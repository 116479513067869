import * as apiService from 'lib/apollo/client'
import DataError, { IsError } from '../../helpers/dataError'
import {
  PrecisefpCreateEngagementDocument,
  PrecisefpCreateEngagementMutation,
  PrecisefpCreateEngagementMutationVariables,
  PrecisefpListAccountsDocument,
  PrecisefpListAccountsQuery,
  PrecisefpListAccountsQueryVariables,
  PrecisefpListTemplatesDocument,
  PrecisefpListTemplatesQuery,
  PrecisefpListTemplatesQueryVariables,
} from '../../types/graphql'

export const createEngagement = async (
  payload: PrecisefpCreateEngagementMutationVariables,
) => {
  const { precisefpCreateEngagement: resp } = await apiService.mutate<
    PrecisefpCreateEngagementMutation,
    PrecisefpCreateEngagementMutationVariables
  >(PrecisefpCreateEngagementDocument, payload)

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.engagementData
}

export const listAccounts = async (
  payload: PrecisefpListAccountsQueryVariables,
) => {
  const { precisefpListAccounts: resp } = await apiService.query<
    PrecisefpListAccountsQuery,
    PrecisefpListAccountsQueryVariables
  >(PrecisefpListAccountsDocument, payload)
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.accounts
}

export const listTemplates = async () => {
  const { precisefpListTemplates: resp } = await apiService.query<
    PrecisefpListTemplatesQuery,
    PrecisefpListTemplatesQueryVariables
  >(PrecisefpListTemplatesDocument)
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.templates
}
