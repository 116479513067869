import { useEffect, useState } from 'react'
import { loadCustomFields } from 'stores/services/wealthbox.service'
import { WealthboxCustomField } from 'types/graphql'

export const useWealthboxCustomFields = () => {
  const [customFields, setCustomFields] = useState<WealthboxCustomField[]>([])

  useEffect(() => {
    const loadFields = async () => {
      try {
        const fields = await loadCustomFields()
        const sorted = [...fields].sort((a, b) => {
          if (!a.name) return 1
          if (!b.name) return -1
          return a.name.localeCompare(b.name)
        })

        setCustomFields(sorted)
      } catch (err) {
        setCustomFields([])
      }
    }

    loadFields()
  }, [])

  return {
    customFields,
  }
}
