import { WealthboxContact } from 'types/graphql'
import { loadContact, loadContacts } from 'stores/services/wealthbox.service'
import { ContactFactory } from '../genericData/generic.factory'
import { contactFields, contactIdFields } from './wealthbox.constants'
import { isEmpty } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { fieldParser } from './wealthbox.fieldparser'

export class WealthboxContactFactory extends ContactFactory<WealthboxContact> {
  dataFields: any = contactFields
  contactIdFields?: string[] = contactIdFields
  firstNameProp: string = 'first_name'
  lastNameProp: string = 'last_name'
  selectedContactId: string
  customFieldParser = fieldParser
  contacts = observable.array<WealthboxContact>([])

  constructor() {
    super('wealthbox-connector')
    makeObservable(this, {
      selectedContactId: observable,
      contacts: observable,
    })
  }

  loadContacts = async (
    name: string = '',
    reset: boolean = false,
  ): Promise<WealthboxContact[]> => {
    if (isEmpty(name) && this.contacts.length && !reset) {
      return this.contacts
    }

    const contacts = await loadContacts(name)
    const sorted = [...contacts].sort((a, b) => {
      const aName = a[this.firstNameProp as keyof WealthboxContact]
      const bName = b[this.firstNameProp as keyof WealthboxContact]

      if (!aName) return 1
      if (!bName) return -1
      return aName.localeCompare(bName)
    })

    this.contacts.replace(sorted)
    return this.contacts
  }

  loadContact = async (contactId: string): Promise<WealthboxContact> => {
    return loadContact(contactId)
  }

  onSelectContact = (contactId: string): void => {
    this.selectedContactId = contactId
  }

  contactIdFunc = (contactId: string): WealthboxContact | undefined => {
    return global.wealthbox._contacts.find(
      (contact) => String(contact.id) === String(contactId),
    )
  }

  contactName = async (contactId: string): Promise<string | undefined> => {
    const contact = await this.loadContact(String(contactId))

    if (!contact) {
      return undefined
    }

    const firstName = contact[this.firstNameProp as keyof WealthboxContact]
    const lastName = contact[this.lastNameProp as keyof WealthboxContact]

    if (!isEmpty(firstName) && !isEmpty(lastName)) {
      return `${firstName} ${lastName}`
    }

    if (!isEmpty(firstName) && isEmpty(lastName)) {
      return `${firstName}`
    }

    if (isEmpty(firstName) && !isEmpty(lastName)) {
      return `${lastName}`
    }

    return undefined
  }
}
