export const ABBR_DATE = 'ddd, MMM Do, YYYY'
export const DATE = 'dddd, MMMM Do, YYYY'
export const DATE_TIME = 'ddd, MMM Do YYYY, h:mm A'
export const SIMPLE_DATE_TIME = 'MMM Do YYYY, h:mm A'
export const SHORT_DATE_TIME = 'ddd, MM-DD-YY, h:mm A'
export const FULL_DATE = 'dddd, MMM Do YYYY'
export const ABBR_FULL_DATE = 'MM-DD-YY, h:mm A'
export const SHORT = 'MM-DD-YYYY'
export const SHORT_INTERNATIONAL = 'DD-MM-YYYY'
export const FULL_MONTH = 'MMMM'
export const TIME = 'h:mm A'
export const FULL = 'MM/DD/YY, HH:mm'

export const formats = {
  ABBR_DATE,
  DATE,
  DATE_TIME,
  SHORT_DATE_TIME,
  FULL_DATE,
  ABBR_FULL_DATE,
  SHORT,
  SHORT_INTERNATIONAL,
  FULL_MONTH,
  TIME,
  FULL,
}

export function LOCAL_SHORT_FORMAT() {
  if (global.data.practice.country === 'USA') {
    return SHORT
  }
  return SHORT_INTERNATIONAL
}

export function LOCAL_CALENDAR_TIME(capitalized = false) {
  if (capitalized) {
    return {
      sameDay: `[Today at] ${TIME}`,
      nextDay: `[Tomorrow at] ${TIME}`,
      nextWeek: 'dddd',
      lastDay: `[Yesterday at] ${TIME}`,
      lastWeek: `[Last] dddd [at] ${TIME}`,
      sameElse: `[On ] ${LOCAL_SHORT_FORMAT()}`,
    }
  }

  return {
    sameDay: `[today at] ${TIME}`,
    nextDay: `[tomorrow at] ${TIME}`,
    nextWeek: 'dddd',
    lastDay: `[yesterday at] ${TIME}`,
    lastWeek: `[last] dddd [at] ${TIME}`,
    sameElse: `[on ] ${LOCAL_SHORT_FORMAT()}`,
  }
}
