/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PreciseFPConfig } from 'components/drawers/Smartfields/types/integration'
import { GenericReplacer } from '../genericData/generic.replacer'
import { ProviderSmartFieldsReplacerProps } from '../provider.interfaces'

export const PreciseFPReplacer: React.FC<
  ProviderSmartFieldsReplacerProps<PreciseFPConfig>
> = (props) => (
  <GenericReplacer<PreciseFPConfig> {...props} provider={'precisefp'} />
)
