import { EventEmitter as EE } from 'ee-ts'
import { LibraryClientNote, LibraryNote } from 'stores/library/library.types'
import { Note } from '../../types/graphql'
import { SummaryModalModes } from '../../components/routes/tasks/modals/SummaryModal/SummaryModal.constants'

export type OpenNewSummary = {
  mode: 'new'
}
export type OpenNewLibrarySummary = {
  mode: 'newForLibrary'
}
export type OpenEditSummary = {
  mode: 'edit'
  summary: LibraryNote
}
export type OpenEditLibrarySummary = {
  mode: 'editFromLibrary'
  summary: LibraryNote
}
export type OpenNewFromMirroredNote = {
  mode: typeof SummaryModalModes.NEW_FROM_MIRRORED
  summary: Note
}

type OpenSummaryModal =
  | OpenNewSummary
  | OpenNewLibrarySummary
  | OpenEditSummary
  | OpenEditLibrarySummary
  | OpenNewFromMirroredNote

export interface Events {
  ADD_BLOCK: () => void
  REMOVE_BLOCK: (block: { id: string }) => void
  BLOCK_DOWN: (block: { id: string }) => void
  BLOCK_UP: (block: { id: string }) => void

  // Library - modals
  OPEN_SUMMARY_MODAL: (args: OpenSummaryModal) => void
  CLOSE_SUMMARY_MODAL: () => void

  // Library - events
  ADD_CLIENT_NOTE_TO_DOC: (notes: LibraryClientNote[]) => void
  ADD_NOTE_TO_DOC: (notes: LibraryNote[]) => void

  // Library - mgmt
  DELETE_SUMMARIES: (summaryIds: string[]) => void
  DUPLICATE_SUMMARY: (summary: LibraryNote) => void

  SUMMARY_CREATED: (summary: LibraryNote) => void
  SUMMARY_DUPLICATED: (summary: LibraryNote) => void
  SUMMARY_UPDATED: (summary: LibraryNote) => void
  SUMMARY_DELETED: (summaryIds: string[]) => void

  // Smartfields
  FILL_SMARTFIELD_MIRRORED_NOTE: (note: Note) => void
  DISABLE_SMARTFIELD_CHANGE: (disable: boolean) => void
}

const bus = new EE<Events>()
type Bus = typeof bus

export { bus, type Bus }
