import * as apiService from 'lib/apollo/client'
import * as GQL from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class CalendarService {
  async getCalendars(grantId: string) {
    const { getCalendars: resp } = await apiService.query(
      GQL.GetCalendarsDocument,
      {
        grantId,
      },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getEventsByCalendar(
    grantId: string,
    calendarId: string,
    start: string,
    end: string,
  ) {
    const { getEventsByCalendar: resp } = await apiService.query(
      GQL.GetEventsByCalendarDocument,
      { grantId, calendarId, start, end },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getCalendarEventSuggestionByEventId(eventId: string) {
    const { getCalendarEventSuggestionByEventId: resp } =
      await apiService.query<
        GQL.GetCalendarEventSuggestionByEventIdQuery,
        GQL.GetCalendarEventSuggestionByEventIdQueryVariables
      >(GQL.GetCalendarEventSuggestionByEventIdDocument, { eventId })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

const calendarService = new CalendarService()

export default calendarService
