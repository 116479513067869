import { formatNum } from 'helpers/num'
import { SmartField } from '../types'
import { SmartFieldConfig, SmartFieldNumberConfig } from '../types/data'

export const numberFormatter = (smartfield: SmartField, value: unknown) => {
  const config = smartfield.config as SmartFieldConfig<SmartFieldNumberConfig>
  const num = value as number

  try {
    switch (config.format) {
      case 'none':
        value = formatNum(num, 'decimal', config.places)
        break
      case 'percent':
        value = formatNum(num, 'percent', config.places)
        break
      case 'currency':
        value = formatNum(num, 'currency', config.places)
        break
      case 'accounting':
        value =
          num < 0
            ? `$(${formatNum(Math.abs(num), 'decimal', config.places)})`
            : `$${formatNum(num, 'decimal', config.places)}`
        break
      case 'financial':
        value =
          num < 0
            ? `(${formatNum(Math.abs(num), 'decimal', config.places)})`
            : formatNum(num, 'decimal', config.places)
        break
    }
  } catch (err) {
    console.log('Unable to format as number!')
  }

  return value
}
