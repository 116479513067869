/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, Input, Select } from 'antd'
import { GenericProviderErrorAlert } from 'components/drawers/Smartfields/smartfield.alerts'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import {
  SmartFieldAllIntegrationConfig,
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import { GenericConnectorConfig } from 'components/drawers/Smartfields/types/integration'
import { smartfieldStore } from 'stores/smartfields'
import { Note } from 'types/graphql'
import { ProviderSmartFieldsReplacerProps } from '../provider.interfaces'
import { ProviderReplacer } from '../provider.replacer'
import { GenericConnectorContacts } from './generic.contacts'
import { ERRORS } from './generic.errors'
import { useGenericModelData, useModelSelects } from './generic.hooks'
import { modelName } from './generic.utils'
import { factoryStore } from './factory.store'

interface GenericSmartFieldsProps<
  TConfig extends SmartFieldAllIntegrationConfig,
> extends ProviderSmartFieldsReplacerProps<TConfig> {
  note: Note
  provider: SmartFieldProvider
  config: SmartFieldConfig<SmartFieldIntegrationConfig<TConfig>>
}

export const GenericReplacer = <
  TConfig extends SmartFieldAllIntegrationConfig,
>({
  provider,
  config,
  form,
  uid,
}: GenericSmartFieldsProps<TConfig>) => {
  const modelConfig = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<GenericConnectorConfig>
  >

  const factory = factoryStore.getFactory(provider)

  const { contact, loading, error, onLoadContacts } = useGenericModelData(
    factory,
    form,
    uid,
  )

  const {
    selects,
    onSelectIndice,
    error: modelError,
  } = useModelSelects(contact, factory, modelConfig.data_fields, form, uid)

  return (
    <ProviderReplacer
      form={form}
      uid={uid}
      error={error}
      data-testid="replace-smartfield-date"
      showOnError
      renderOnError={() => (
        <GenericConnectorContacts
          factory={factory}
          loading={loading}
          onLoadContacts={onLoadContacts}
          form={form}
          uid={uid}
        />
      )}
    >
      <>
        {modelError && (
          <>
            <GenericProviderErrorAlert
              error={modelError}
              style={{ marginBottom: 16 }}
            />
            <br />
          </>
        )}

        {!modelError &&
          selects.map((select, index) => {
            if (!select?.data || !select?.data.length) {
              return (
                <>
                  <GenericProviderErrorAlert
                    error={select.help ?? ERRORS.NO_DATA_ERROR}
                    style={{ marginBottom: 16 }}
                    testid="provider-error"
                  />

                  <Form.Item
                    name={uid}
                    label={
                      <FormItemLabel
                        label="Manual Override"
                        description="Add a manual override to build your document"
                      />
                    }
                    data-testid="manual-override-input"
                    initialValue={smartfieldStore?.currentValue}
                    normalize={(value) => (value === '' ? null : value)}
                  >
                    <Input size="large" autoFocus allowClear />
                  </Form.Item>
                  <br />
                </>
              )
            }

            return (
              <Form.Item
                name={`select-${index}`}
                rules={[{ required: true, message: 'Please select a value' }]}
                label={
                  <FormItemLabel
                    label={select.title}
                    description={`Select required field`}
                  />
                }
              >
                <Select
                  onChange={(val) => onSelectIndice(val, index)}
                  placeholder={`Select ${select.title}`}
                  style={{ width: '100%' }}
                  size="large"
                  showSearch
                  filterOption={(input, option) =>
                    (`${option?.label}` ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {select.data.map((b: any, i: number) => {
                    const name = modelName(b)
                    return (
                      <Select.Option value={i} label={name}>
                        {name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            )
          }, undefined)}
      </>
    </ProviderReplacer>
  )
}
