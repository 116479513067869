import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import { PreciseFPContactFactory } from '../precisefp/precisefp.factory'
import { WealthboxContactFactory } from '../wealthbox/wealthbox.factory'
import { ContactFactory } from './generic.factory'

class FactoryStore {
  factories = new Map<string, ContactFactory<any>>()

  getFactory(provider: SmartFieldProvider): ContactFactory<any> {
    if (!this.factories.has(provider)) {
      switch (provider) {
        case 'wealthbox-connector':
          this.factories.set(provider, new WealthboxContactFactory())
          break
        case 'precisefp':
          this.factories.set(provider, new PreciseFPContactFactory())
          break
        default:
          break
      }
    }

    return this.factories.get(provider) as ContactFactory<any>
  }
}

const factoryStore = new FactoryStore()
export { factoryStore }
