/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Space } from 'antd'
import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeConfig,
  RiskalyzeQuestionaireConfig,
} from 'components/drawers/Smartfields/types/integration'
import { useEffect } from 'react'
import styles from 'styles'
import { FormItemLabel } from '../../../../../smartfield.styles'
import { ProviderReplacerBaseProps } from '../../../provider.interfaces'
import { ProviderReplacer } from '../../../provider.replacer'
import { useRiskalyzeQuestionaireData } from '../../riskalyze.hooks'
import { RiskalyzeClients } from '../riskalyze.clients'

interface RiskalyzeQuestionaireSmartFieldsProps
  extends ProviderReplacerBaseProps {
  config: SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>
}

export const RiskalyzeQuestionaireSmartFields: React.FC<
  RiskalyzeQuestionaireSmartFieldsProps
> = ({ form, config, uid }) => {
  const questionaireConfig = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeQuestionaireConfig>>
  >
  const selectedContact = Form.useWatch('questionaire_contact', form)
  const {
    clients,
    error,
    onSelectClient,
    onSelectInvested,
    onGenerateLink,
    generatingLink,
  } = useRiskalyzeQuestionaireData(questionaireConfig, form, uid)

  const disableGenerateButton =
    Boolean(form.getFieldValue('questionaire_link')) ||
    !Boolean(form.getFieldValue('questionaire_contact'))
  const isLinkCreated = Boolean(form.getFieldValue('questionaire_link'))

  return (
    <ProviderReplacer
      form={form}
      uid={uid}
      error={error}
      // label={config?.label}
      data-testid="replace-smartfield-date"
      showOnError
      allowOverride={false}
    >
      <RiskalyzeClients
        form={form}
        formName="questionaire_contact"
        clients={clients}
        loading={false}
        onChange={onSelectClient}
      />

      <Form.Item
        name="questionaire_invested"
        rules={[{ required: true, message: 'Please enter a value' }]}
        label={
          <FormItemLabel
            label="Amount Invested (optional)"
            description="Select the amount invested"
          />
        }
      >
        <InputNumber
          onChange={onSelectInvested}
          size="large"
          disabled={!selectedContact}
          style={{ width: 160 }}
        />
      </Form.Item>

      <Form.Item
        name="questionaire_link_title"
        rules={[{ required: false, message: 'Title for the url' }]}
        label={
          <FormItemLabel
            label="Link Title (optional)"
            description="Add a title to the questionnaire url"
          />
        }
      >
        <Input
          size="large"
          disabled={!selectedContact}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item name="questionaire_link" hidden>
        <Input />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            onClick={onGenerateLink}
            disabled={generatingLink || disableGenerateButton}
            loading={generatingLink}
          >
            Generate Link
          </Button>
          {isLinkCreated && (
            <CheckCircleOutlined style={{ color: styles.colors.primary }} />
          )}
        </Space>
      </Form.Item>
    </ProviderReplacer>
  )
}
