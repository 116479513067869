/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  GenericConnectorConfig,
  WealthboxConnectorConfig,
} from 'components/drawers/Smartfields/types/integration'
import { GenericReplacer } from '../genericData/generic.replacer'
import { ProviderSmartFieldsReplacerProps } from '../provider.interfaces'

export const WealthboxReplacer: React.FC<
  ProviderSmartFieldsReplacerProps<GenericConnectorConfig>
> = (props) => (
  <GenericReplacer<WealthboxConnectorConfig>
    {...props}
    provider="wealthbox-connector"
  />
)
