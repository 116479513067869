import { Form, FormInstance, Select, Spin } from 'antd'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { debounce, isEmpty } from 'lodash'
import { autorun } from 'mobx'
import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseContactType, ContactFactory } from './generic.factory'

interface GenericContactsProps<TContactType extends BaseContactType> {
  factory: ContactFactory<TContactType>
  loading: boolean
  onLoadContacts?: (name?: string, reset?: boolean) => void
  form: FormInstance
  uid: string
}

export const GenericConnectorContacts = observer(
  <TContactType extends BaseContactType>({
    factory,
    loading,
    onLoadContacts,
    form,
    uid,
  }: GenericContactsProps<TContactType>) => {
    const [visible, setVisible] = useState(false)

    const { firstNameProp, lastNameProp, onSelectContact, selectedContactId } =
      factory

    const onSelect = useCallback(
      (contactId: string) => {
        setVisible(false)
        onSelectContact?.(contactId)
      },
      [onSelectContact],
    )

    const onSearch = debounce((value: string) => {
      onLoadContacts?.(value)
    }, 300)

    const onSearchClear = useCallback(() => {
      onLoadContacts?.('', true)
    }, [onLoadContacts])

    useEffect(() => {
      return autorun(() => {
        const contact = form.getFieldValue('contact_id')

        if (factory.contacts.length && isEmpty(contact) && selectedContactId) {
          form.setFieldValue('contact_id', selectedContactId)
        }
      })
    }, [factory.contacts, form, selectedContactId, uid])

    return (
      <Form.Item
        name="contact_id"
        rules={[{ required: true, message: 'Please select a contact' }]}
        label={<FormItemLabel label="Contact" description="Select a contact" />}
      >
        <Select
          showSearch
          placeholder={loading ? 'Fetching contacts..' : 'Select a contact'}
          optionFilterProp="label"
          size="large"
          allowClear
          optionLabelProp="label"
          onSearch={onSearch}
          onClear={onSearchClear}
          notFoundContent={loading ? <Spin size="small" /> : null}
          loading={loading}
          onDropdownVisibleChange={setVisible}
          open={visible}
          onSelect={onSelect}
          disabled={loading}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            (`${option?.label}` ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {factory.contacts.map((contact) => (
            <Select.Option
              value={contact.id}
              label={`${contact[firstNameProp]} ${contact[lastNameProp]}`}
            >
              {`${contact[firstNameProp]} ${contact[lastNameProp]}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )
  },
)
