import { FieldParserType } from '../genericData/generic.factory'

type CustomField = {
  name: string
  id: number
  field_type:
    | 'multi_check_box'
    | 'single_select'
    | 'date'
    | 'check_box'
    | 'text_field'
    | 'text_area'
  document_type: string
  value: string
}

export const fieldParser: FieldParserType = (client, allFields, onUpdate) => {
  if (allFields.length > 1 && allFields[0] === 'custom_fields') {
    const customFields: CustomField[] = client?.custom_fields?.filter(
      (cf: any) => cf.id === Number(allFields[1]),
    )

    if (customFields.length) {
      const value = customFields.map((cf) => cf.value).join(', ')

      onUpdate(value)
      if (value) return []
    }

    onUpdate(null)
    return [
      {
        title: 'Custom Field',
        help: "No values for this Contact's Custom Field",
        data: [],
      },
    ]
  }

  return undefined
}
