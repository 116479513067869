import { PreciseFpClient } from 'types/graphql'
import { ContactFactory } from '../genericData/generic.factory'
import {
  listAccounts,
} from 'stores/services/precisefp.service'
import { compact, isEmpty } from 'lodash'
import { observable } from 'mobx'

export class PreciseFPContactFactory extends ContactFactory<PreciseFpClient> {
  dataFields: any = {}
  contactIdFields?: string[] = []
  firstNameProp: string = 'first_name'
  lastNameProp: string = 'last_name'
  selectedContactId: string
  contacts = observable.array<PreciseFpClient>([])
  customFieldParser = undefined

  constructor() {
    super('precisefp')
  }

  async loadContacts(
    name: string = '',
    reset: boolean = false,
  ): Promise<PreciseFpClient[]> {
    if (isEmpty(name) && this.contacts.length && !reset) {
      return this.contacts
    }

    const accounts = await listAccounts({ query: name })
    const contacts = accounts?.map((account) => account.client)

    const sorted = compact(
      [...contacts].sort((a, b) => {
        const aName = a?.[this.firstNameProp as keyof PreciseFpClient] as string
        const bName = b?.[this.firstNameProp as keyof PreciseFpClient] as string

        if (!aName) return 1
        if (!bName) return -1
        return aName.localeCompare(bName)
      }),
    )

    this.contacts.replace(sorted)
    return this.contacts
  }

  async loadContact(contactId: string): Promise<PreciseFpClient | undefined> {
    return Promise.resolve(undefined)
  }

  async onSearchContact(search: string): Promise<PreciseFpClient[]> {
    return []
  }

  onSelectContact(contactId: string): void {
    // global.precisefp. = contactId
  }

  contactIdFunc(contactId: string): PreciseFpClient | undefined {
    return undefined
  }

  contactName(contactId: string): Promise<string> {
    return Promise.resolve('Unknown contact')
  }
}
