import { SmartFieldProvider, SmartFieldTitle } from '../../types'
import { genericConfigParser, genericParser } from './genericData/parser'
import { HolistiplanBuilder } from './holistiplan'
import { HolistiplanReplacer } from './holistiplan/holistiplan.replacer'
import {
  holistiplanConfigParser,
  holistiplanParser,
} from './holistiplan/parser'
import { PreciseFPBuilder } from './precisefp'
import { preciseFPConfigParser, preciseFPParser } from './precisefp/parser'
import { PreciseFPReplacer } from './precisefp/precisefp.replacer'
import {
  ConfigParser,
  FormParser,
  ProviderSmartFieldsBuilderProps,
  ProviderSmartFieldsReplacerProps,
} from './provider.interfaces'
import { RiskalyzeBuilder } from './riskalyze'
import { riskalyzeConfigParser, riskalyzeParser } from './riskalyze/parser'
import { RiskalyzeReplacer } from './riskalyze/riskalyze.replacer'
import { WealthboxBuilder } from './wealthbox/wealthbox.builder'
import { WealthboxReplacer } from './wealthbox/wealthbox.replacer'

export type ProviderConnector = {}

export type ProviderConfiguration = {
  provider: SmartFieldProvider
  title: SmartFieldTitle
  smartfieldBuilder: React.FC<ProviderSmartFieldsBuilderProps>
  smartfieldReplacer: React.FC<ProviderSmartFieldsReplacerProps<any>>
  formParser: FormParser
  configParser: ConfigParser
  permissionKey?: string
}

export const getProviderConfig = (
  provider: SmartFieldProvider,
): ProviderConfiguration =>
  providerConfig.get(provider) as ProviderConfiguration

export const providerConfig: Map<SmartFieldProvider, ProviderConfiguration> =
  new Map([
    [
      'holistiplan',
      {
        title: 'Holistiplan',
        provider: 'holistiplan',
        smartfieldBuilder: HolistiplanBuilder,
        smartfieldReplacer: HolistiplanReplacer,
        formParser: holistiplanParser,
        configParser: holistiplanConfigParser,
        permissionKey: 'ui.app.connectors.holistiplan',
      },
    ],
    [
      'precisefp',
      {
        title: 'PreciseFP',
        provider: 'precisefp',
        smartfieldBuilder: PreciseFPBuilder,
        smartfieldReplacer: PreciseFPReplacer,
        formParser: preciseFPParser,
        configParser: preciseFPConfigParser,
        permissionKey: 'ui.app.connectors.precisefp',
      },
    ],
    [
      'riskalyze',
      {
        title: 'Nitrogen',
        provider: 'riskalyze',
        smartfieldBuilder: RiskalyzeBuilder,
        smartfieldReplacer: RiskalyzeReplacer,
        formParser: riskalyzeParser,
        configParser: riskalyzeConfigParser,
        permissionKey: 'ui.app.connectors.riskalyze',
      },
    ],
    [
      'wealthbox-connector',
      {
        title: 'Wealthbox (Data)',
        provider: 'wealthbox-connector',
        smartfieldBuilder: WealthboxBuilder,
        smartfieldReplacer: WealthboxReplacer,
        formParser: genericParser,
        configParser: genericConfigParser,
        permissionKey: 'ui.app.connectors.wealthbox',
      },
    ],
    [
      'zoho',
      {
        title: 'Zoho',
        provider: 'zoho',
        smartfieldBuilder: WealthboxBuilder,
        smartfieldReplacer: WealthboxReplacer,
        formParser: genericParser,
        configParser: genericConfigParser,
        permissionKey: 'ui.app.connectors.zoho',
      },
    ],
  ])
