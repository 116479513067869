import * as apiService from 'lib/apollo/client'
import {
  WealthboxContact,
  WealthboxContactDocument,
  WealthboxContactQuery,
  WealthboxContactQueryVariables,
  WealthboxContactsDocument,
  WealthboxContactsQuery,
  WealthboxContactsQueryVariables,
  WealthboxCustomField,
  WealthboxCustomFieldsDocument,
  WealthboxCustomFieldsQuery,
  WealthboxCustomFieldsQueryVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

export const loadContacts = async (
  name: string,
): Promise<WealthboxContact[]> => {
  const { wealthboxContacts: resp = [] } = await apiService.query<
    WealthboxContactsQuery,
    WealthboxContactsQueryVariables
  >(WealthboxContactsDocument, {
    name,
  })
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp
}

export const loadContact = async (contactId: string): Promise<any> => {
  const { wealthboxContact: resp } = await apiService.query<
    WealthboxContactQuery,
    WealthboxContactQueryVariables
  >(WealthboxContactDocument, {
    contactId,
  })

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp as any
}

export const loadCustomFields = async (): Promise<WealthboxCustomField[]> => {
  const { wealthboxCustomFields: resp } = await apiService.query<
    WealthboxCustomFieldsQuery,
    WealthboxCustomFieldsQueryVariables
  >(WealthboxCustomFieldsDocument)

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp
}
